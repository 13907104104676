<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title
        >{{ data.name ? data.name : "Новый" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model
            v-model="data"
            :model="model"
            :errors="errors"
            @validate="validate($event)"
          />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="submit_()" color="primary">Записать</v-btn>
        <v-btn v-if="id" @click="removeDialogShow = true" color="error"
          >Удалить</v-btn
        >
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl],
  props: {
    value: Boolean,
    id: Number,
    object_id: Number,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: this.$store.getters["config/get"].models.events.form,
      api: "/mechti/events",
      removeDialogShow: false,
    };
  },
  computed: {},
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
        this.data.object_id = this.object_id;
      }
    },
  },
  methods: {
    submit_() {
      let dt = this.data.eventon ? new Date(this.data.eventon) : new Date();
      this.data.eventon = new Date(dt.date + " " + dt.time);
      this.submit();
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>